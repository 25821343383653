import React from 'react';
import { makeStyles } from '@material-ui/core';

export default function QuoteImage(props) {

    const useStyles = makeStyles(theme => ({
        imageView: {
            borderRadius: props.radius || 15,
            height: props.height || 150,
            width: props.width || 300
        }
      }));
    
      const classes = useStyles();
    return (props.url === null ? '' : <img alt="home view" src={props.url} className={classes.imageView} />);
}
