import React, { useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from "@material-ui/core";

import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import * as _ from "lodash";
import "./scheduling.component.css";

import * as fetch from "fetch";
import { duration } from "moment";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";

const AppointmentScheduler = props => {
  const {
    getServmanInfo,
    triggerNextStep,
    servmanUrl,
    botId,
    companyId,
    clientInfo,
    getAnswers,
    tos = "terms here"
  } = props;
  const [selectedDate, setSelectedDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  });
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [dates, setDates] = useState([]);
  const [servmanInfo, setServmanInfo] = useState(null);
  const [order, setOrder] = useState(null);
  const [displayContactOffice, setDisplayContactOffice] = useState(false);

  useEffect(() => {
    // get the current date and the next six days
    const currentDate = new Date();

    // setSelectedDate(currentDate);

    const info = getServmanInfo();
    setServmanInfo(info);
  }, []);

  useEffect(() => {
    if (!servmanInfo || order) return;
    setLoading(true);

    const { selectedQuestion } = servmanInfo;
    const { client_id, contact_id } = clientInfo;

    const question = servmanInfo.problems.find(
      question => question.name === selectedQuestion
    );

    if (!question) {
      setLoading(false);
      setDisplayContactOffice(true);
      return;
    }

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      payload: JSON.stringify({
        companyId: companyId,
        botId: botId,
        client_id,
        contact_id,
        question_id: question.id
      })
    };
    fetch.fetchUrl(
      servmanUrl + `/v1/servman/order`,
      requestPayload,
      (error, meta, body) => {
        const statusCode = meta.status;
        if (statusCode === 200) {
          const str_body = body.toString();
          const jsonBody = JSON.parse(str_body);

          if (Array.isArray(jsonBody.data) && jsonBody.data.length > 0) {
            setOrder(jsonBody.data[0]);
          }
        }
        if (error) {
          console.log(error);
        }
      }
    );
  }, [servmanInfo]);

  useEffect(() => {
    if (!order) return;
    fetchTimeSlots();
  }, [order]);

  useEffect(() => {
    if (!selectedDate || !order) return;
    fetchTimeSlots();
  }, [selectedDate]);

  const handleTimeSlotClick = timeSlot => {
    if (isConfirmed) return;
    setSelectedTimeSlot(timeSlot);
  };

  const displayTime = slot => {
    const time = new Date(slot.datetime_ticks / 10000);
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true
    });
  };

  const fetchTimeSlots = () => {
    setLoading(true);
    setSelectedTimeSlot(null);
    // make a request to your server to get the time slots for the selected date
    // and update the timeSlots state with the response
    const { selectedQuestion } = servmanInfo;
    const { client_id, contact_id } = clientInfo;

    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    const selectDayString = `${selectedDate.year}-${(
      "0" + selectedDate.month
    ).slice(-2)}-${("0" + selectedDate.day).slice(-2)}T00:00:00.000Z`;
    const selectDay = new Date(selectDayString.split("T")[0]);
    let nextDayIndex = selectDay.getDay() + 1;
    if (nextDayIndex > 6) nextDayIndex = 0;
    const day = days[nextDayIndex];

    const question = servmanInfo.problems.find(
      question => question.name === selectedQuestion
    );

    if (!question) {
      setLoading(false);
      setDisplayContactOffice(true);
      return;
    }

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      payload: JSON.stringify({
        companyId: companyId,
        botId: botId,
        client_id,
        date: selectDayString,
        order_id: order.id,
        branchId: servmanInfo.branchId,
        question_id: question.id,
        day: day
      })
    };
    fetch.fetchUrl(
      servmanUrl + `/v1/servman/schedule`,
      requestPayload,
      (error, meta, body) => {
        const statusCode = meta.status;
        if (statusCode === 200) {
          const str_body = body.toString();
          const jsonBody = JSON.parse(str_body);
          console.log("in scheduling thing", error, meta, jsonBody);
          if (jsonBody && jsonBody.data) {
            setTimeSlots(jsonBody.data);
          } else {
            setTimeSlots([]);
          }
          setLoading(false);
        }
        if (error) {
          setTimeSlots([]);
          setLoading(false);
        }
      }
    );
  };

  const confirmTimeSlot = async () => {
    const { selectedQuestion } = servmanInfo;
    const { client_id, contact_id } = clientInfo;

    const answers = await getAnswers();
    answers["Accepted.Terms"] = true;
    const keys = Object.keys(answers);
    const notes = keys
      .filter(x => x !== "Greeting.Selection" && x !== ";")
      .map(key => {
        return `${key}: ${answers[key]}`;
      })
      .join("\r\n");

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      payload: JSON.stringify({
        companyId: companyId,
        botId: botId,
        order_id: order.id,
        datetime: selectedTimeSlot.datetime_ticks,
        employee_id: selectedTimeSlot.employee_id,
        service_window_id: selectedTimeSlot.service_window_id,
        day: selectedDate.day,
        notes: [notes]
      })
    };
    fetch.fetchUrl(
      servmanUrl + `/v1/servman/schedule/confirm`,
      requestPayload,
      (error, meta, body) => {
        const statusCode = meta.status;
        if (statusCode === 200) {
          setIsConfirmed(true);
          triggerNextStep({ value: selectedTimeSlot, sendAsUserMessage: true });
        }
        if (error) {
          console.log(error);
        }
      }
    );
  };

  const today = utils().getToday();
  const nextYear = { ...today, year: today.year + 1 };

  return loading ? (
    <div style={{ minHeight: 500, display: "flex", flexDirection: "column" }}>
      <h1>Loading...</h1>
      <CircularProgress />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "sans-serif",
        minHeight: 500
      }}
    >
      <FormControl style={{ marginBottom: 40 }}>
        <Calendar
          value={selectedDate}
          minimumDate={today}
          maximumDate={nextYear}
          onChange={setSelectedDate}
        />
      </FormControl>
      {/* <Button onClick={fetchTimeSlots}>Show time slots</Button> */}
      <h3>
        {isConfirmed
          ? "Your Confirmed Service Time"
          : "Available Service Times"}
      </h3>
      {timeSlots.length > 0 ? (
        <div style={{ width: 320, maxWidth: 320 }}>
          {_.uniqBy(timeSlots, "service_window_desc").map(timeSlot => (
            <Button
              className={
                timeSlot === selectedTimeSlot ? "slot slot-selected" : "slot"
              }
              color="primary"
              style={{
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
                marginBottom: "20px",
                border: "1px solid black",
                marginBottom: "11px",
                // borderRadius: 15,
                cursor: "pointer",

                boxShadow:
                  timeSlot === selectedTimeSlot
                    ? "0px 0px 10px 0px rgba(0,0,0,0.75)"
                    : "none",
                transition: "all 0.2s ease-in-out"
              }}
              key={`${timeSlot.employee_id}-${timeSlot.service_window_id}`}
              onClick={() => handleTimeSlotClick(timeSlot)}
              selected={timeSlot === selectedTimeSlot}
            >
              <span>{timeSlot.service_window_desc}</span>
            </Button>
          ))}
        </div>
      ) : (
        <h2>No Available Time Slots</h2>
      )}
      <p style={{ width: 320, maxWidth: 320 }}>
        Please choose your desired service time. If none of the available times
        are suitable, please contact our office directly for further assistance.
      </p>
      {selectedTimeSlot && (
        <div style={{ marginTop: 20, width: 320, maxWidth: 320 }}>
          <h4>Terms of Service</h4>
          <p style={{ fontSize: 12 }}>
            By clicking the "Confirm time slot" button, you agree to the
            following terms of service:
            <br />
            {tos}
          </p>
        </div>
      )}
      {selectedTimeSlot && !isConfirmed && (
        <Button
          style={{
            alignSelf: "center",
            width: 280,
            maxWidth: 280,
            marginTop: 20,
            backgroundColor: "#1565C0",
            color: "white",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            transition: "all 0.2s ease-in-out"
          }}
          onClick={confirmTimeSlot}
        >
          Confirm time slot
        </Button>
      )}
    </div>
  );
};

export default AppointmentScheduler;
