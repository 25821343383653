import React from "react";
import { InlineUserInput } from "../components/inline-user-input.component";
import { HtmlMessageComponent } from "../components/html-message.component";

export function generateObjectionSteps(self) {
  const q = [
    {
      name: "",
      type: "objections",
      steps: [
        {
          id: "objection-entry",
          message: `I need to think about it`,
          trigger: "ask-objection"
        },
        {
          id: "ask-objection",
          message: `No problem, I understand.  There is a lot to consider when choosing a company… What do you still need to think about?`,
          trigger: "get-objection-reason"
        },

        {
          id: "rebutal-positive",
          message: `Wonderful, let's go back to your quote and get you started!`,
          trigger: "thanks-for-business"
        },
        {
          id: "thanks-for-business",
          message: "We look forward to doing business with you",
          trigger: () => {
            setTimeout(() => {
              self._modal.current.handleOpen();
            }, 3000);

            return "re-opening-quote";
          }
        },
        { id: "re-opening-quote", message: "Re-opening your quote", end: true },

        {
          id: "well-thanks-anyway",
          message:
            "Our instant quote technology is good, however we feel it would be best for us to manually review your property with you. We will be in touch soon.",
          trigger: "ask-comments"
        },
        {
          id: "ask-comments",
          message: "Do you have any additional comments for our team?",
          trigger: "get-comments"
        },
        {
          id: "will-contact-you",
          message: "Awesome, our team will be in contact with you shortly.",
          end: true
        }
      ],
      probing_questions: [
        {
          id: "get-objection-reason",
          options: [
            {
              value: "Price",
              label: "Price",
              iconClass: ["fas", "dollar-sign"],
              color: "yellow"
            },
            {
              value: "Product",
              label: "Product",
              iconClass: ["fas", "cog"],
              color: "yellow"
            },
            {
              value: "People",
              label: "People",
              iconClass: ["fas", "user-alt"],
              color: "yellow"
            }
          ],
          optionType: "icon-text-option",
          fillOptions: true,
          setVariable: "objectionReason"
        },
        {
          id: "get-comments",
          component: (
            <InlineUserInput
              placeholder={"Comments for Our Team"}
              name={"comments"}
            />
          ),
          waitAction: true,
          trigger: "thanks-for-feedback",
          setVariable: "feedback"
        },
        {
          id: "thanks-for-feedback",
          component: <HtmlMessageComponent />,
          inputAttributes: {
            html: () => {
              return `<img width="100%" src="/assets/images/mailbox.gif" />`;
            }
          },
          asMessage: true,
          dispatchMessage: () => {
            const self2 = self;
            setTimeout(() => {
              self2.sendLeadInfo();
            }, 1000);
          },
          trigger: "will-contact-you"
        }
      ]
    }
  ];

  return q;
}
