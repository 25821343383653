import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import QuoteIOSSwitch from "../switch.component";
import * as _ from "lodash";

import {
  priceCalculations,
  offers as LBOffers,
  installments
} from "@workwave_it/lawnbot-pricing-calculations";
import { currencies } from "../../currencies/currencies";

export default function QuotePriceBlock(props) {
  const [state] = useState(props);

  let currentCurrency = "$";
  currencies &&
    currencies.forEach(currency => {
      if (currency.name === props.currency) {
        currentCurrency = currency.symbol;
      }
    });

  const useStyles = makeStyles(theme => ({
    blockContainer: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      minHeigth: 78,
      background: "#DBF3FF",
      borderRadius: 10,
      border: "1px solid #3597EC",
      paddingTop: 15,
      paddingLeft: 15,
      marginBottom: 20,
      marginLeft: "2%",
      fontFamily: `'Open Sans', sans-serif`
    },
    unselectedBlockContainer: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      background: "#FFF",
      borderRadius: 10,
      border: "1px solid #3597EC",
      paddingTop: 15,
      paddingLeft: 15,
      marginBottom: 20,
      marginLeft: "2%"
    },
    serviceBlock: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 10,
      minWidth: "70%"
    },
    priceBlock: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 10,
      minWidth: "25%"
    },
    boldServiceText: {
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: 700
    },
    disabledServiceText: {
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: 18,
      color: "#707070",
      fontWeight: 700
    },
    serviceDescription: {
      fontSize: 11,
      marginBottom: 20
    },
    perVisit: {
      fontSize: 12,
      color: "#ccc",
      lineHeight: 1
    }
  }));

  const classes = useStyles();

  let hasOffers = props.offers.filter(i => !i.hide).length ? true : false;
  let offerText = "";
  let offer = {};

  let firstDisplay;

  let price = "NA";

  const filterFirstLabel = label => {
    if (label.trim() === "1st") {
      return "";
    }

    return label;
  };

  const displayLabel =
    props.applicationLabel.charAt(0).toUpperCase() +
    props.applicationLabel.slice(1);

  /* this is the new lawnbot pricing calculation stuff */
  const services = props.services
    ? props.term === "total"
      ? props.services.prePay
      : props.services.application
    : [];
  const applications =
    services && Array.isArray(services)
      ? services.filter(s => !s.tooLateToDo).length
      : [];

  let usesUpfrontPricing =
    props.services.application[0].usesUpfrontPricing || false;

  firstDisplay = priceCalculations.getDisplayPricing(
    props,
    props.term,
    props.autoPayPriceMethod,
    props.autoPayMonthlyPricePeriod,
    props.serviceTax,
    props.lookupZipCodeTax
  );

  if (usesUpfrontPricing && props.term === "total") {
    firstDisplay = null;
    const totalTotals = props.pricing.filter(x => x.frequencyType === 'total');

    if(totalTotals.length) {
      firstDisplay = totalTotals[0].servicePrice;
    }

    if(!firstDisplay) {
      const upfront = props.services.application[0].grossPrice;
      const recurring = props.services.application[0].recurringPrice;
      firstDisplay = upfront + props.services?.prePay?.length * recurring;
    }
  }

  offer = props.offers[0];
  offerText = props.hideDiscountText
    ? ""
    : props.realTime
    ? LBOffers.createRTPText(
        props.services[props.term === "total" ? "prePay" : "application"],
        currentCurrency,
        props.term === "total"
      )
    : hasOffers
    ? LBOffers.createOfferText(
        props.services[props.term === "total" ? "prePay" : "application"][0]
          .grossPrice,
        offer,
        props.applicationLabel,
        currentCurrency
      )
    : props.term === "total" && props.prePayDiscount
    ? "" + props.prePayDiscount + "% off for pre-paying"
    : "";

  // console.log(props)

  const labels = installments.getInstallmentsLabels(
    props.autoPayPriceMethod,
    props.autoPayMonthlyPricePeriod,
    props.applicationLabel,
    props.autoPayAlternativeLabel
  );

  if (usesUpfrontPricing) {
    labels.switchLabel = LBOffers.createRTPText(
      props.services.application
    ).replace("per month", "per installment");
  }

  /* end new lawnbot pricing calculation stuff */
  return (
    <div
      className={
        props.selected
          ? classes.blockContainer
          : classes.unselectedBlockContainer
      }
    >
      <div
        className={
          props.selected
            ? classes.serviceBlock
            : `${classes.disabledServiceText} ${classes.serviceBlock}`
        }
      >
        <div className={classes.boldServiceText}>
          {props.serviceName || "WEEKLY MOWING"}
        </div>
        <div className={classes.serviceDescription}>
          {props.serviceDescription ||
            `Cutting, edging & blowing off all hard 
surfaces every 7-10 days`}
          <br />
          <br />
          {!usesUpfrontPricing ? (
            <span style={{ fontWeight: "bold", fontSize: 13 }}>
              {displayLabel.trim() ? `${applications}` : ""} {displayLabel}
              {displayLabel.trim() && applications > 1 ? "s" : ""}{" "}
              {props.seasonalText}
            </span>
          ) : null}
        </div>
      </div>
      <div className={classes.priceBlock}>
        <div
          className={
            props.selected
              ? classes.boldServiceText
              : classes.disabledServiceText
          }
        >
          {currentCurrency}
          {firstDisplay ? firstDisplay.toFixed(2) : "0"}
        </div>
        <div className={classes.perVisit}>
          {props.term === "total"
            ? "total"
            : filterFirstLabel(labels.switchLabel)}
        </div>
        <QuoteIOSSwitch {...state} />
        <p
          style={{
            fontSize: 10,
            fontWeight: 600,
            fontWeight: "bold",
            color: "red"
          }}
        >
          {offerText}
        </p>
      </div>
    </div>
  );
}
