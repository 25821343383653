import React from "react";
import QuoteImage from "../quote.image.component";
import { makeStyles } from "@material-ui/core";

export default function QuoteHeader(props) {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      flexWrap: "wrap",
      backgroundColor: "#F0F0F0",
      paddingBottom: 25,
      paddingTop: 15,
      fontFamily: `'Open Sans', sans-serif`
    },
    header: {
      margin: "auto"
    },
    headerText: {
      textAlign: "center",
      marginBottom: 5,
      fontSize: 20,
      textTransform: "uppercase",
      lineHeight: 1.0,
      fontFamily: `'Open Sans', sans-serif`,
      fontWeight: 900
    },
    headerName: {
      fontWeight: 500,
      letterSpacing: 1.8,
      marginBottom: 10
    },
    imageView: {
      borderRadius: props.radius || 15,
      height: props.height || 200
    },
    yearly: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      margin: "auto"
    },
    sizeEstimate: {
      textTransform: "uppercase",
      color: "grey",
      textAlign: "center",
      margin: "auto"
    }
  }));

  const classes = useStyles();

  let label = "sq ft";
  let action = "colored";
  if (props.measurementMode === "perimeter") {
    action = "outlined";
    if (props.isMetric) {
      label = "LINEAR METERS";
    } else {
      label = "LINEAR FEET";
    }
  } else {
    if (props.isMetric) {
      label = "SQUARE METERS";
    } else {
      label = "SQUARE FEET";
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerText}>
          {props.customerRecord.address || ""}
        </div>
        <div className={`${classes.headerText} ${classes.headerName}`}>
          {props.customerRecord.name || "Fake Name Here"}
        </div>
        <QuoteImage {...props} />
        <div className={classes.sizeEstimate}>
          {Math.round((props.sqft + Number.EPSILON) * 100) / 100 || "4,000"}{" "}
          {label}
        </div>
      </div>
    </div>
  );
}
