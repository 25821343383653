import { Link, Button, Grid, Typography, styled } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect, useState } from "react";

const HorizontalGrid = styled(Grid)({
  flexDirection: "column",
  textAlign: "center",
  display: "flex",
  flex: "1",
  padding: "0px 15px",
  alignItems: "center",
  justifyContent: "center"
});

export function LeadTimeoutComponent(props) {
  const { timeoutTime, formFields, phone, logo } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (formFields?.customerName && timeoutTime?.current) {
        const currentTime = new Date().getTime() / 1000;
        const secondsSinceLastUpdate = currentTime - timeoutTime.current;       

        if (secondsSinceLastUpdate > (60 * 7.75)) {
          setShow(true);
        }
      }
    }, 2000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [timeoutTime, formFields]);

  return (
    <Grid
      style={{
        display: show ? "flex" : "none",
        flexDirection: "column",
        background: "#FFF",
        position: "absolute",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        zIndex: 10000001
      }}
    >
      <HorizontalGrid>
        <CheckCircleIcon
          style={{ color: "#1565C0", fontSize: "50px" }}
        ></CheckCircleIcon>
        <Typography variant="h4">
          Your information has been sent to our team.
        </Typography>
        <Typography>A representative will contact you shortly.</Typography>
      </HorizontalGrid>

      <HorizontalGrid>
        <Typography style={{ paddingBottom: "10px" }}>
          In case of an emergency
        </Typography>
        <Link
          href={`tel: +1${phone}`}
          component={Button}
          style={{
            textDecoration: "none",
            backgroundColor: "#1565C0",
            color: "white"
          }}
        >
          Contact Us
        </Link>
      </HorizontalGrid>
      <HorizontalGrid>
        <img src={logo} style={{ maxHeight: "150px" }} />
      </HorizontalGrid>
    </Grid>
  );
}
