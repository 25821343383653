import _ from "lodash";
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { useState } from "react";
export class FirstLastNameCombo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      visible: true
    };
  }

  inputStyle = {
    // maxWidth: 400,
    marginLeft: 10,
    // minWidth:400,
    width: "100%",
    borderRadius: 4,
    boxShadow: "inset 0 0 2px #E53935",
    boxSizing: "border-box",
    color: "#0E283FD9",
    fontSize: 16,
    outline: "none",
    "WebkitAppearance": "none",
    borderWidth: 2,
    borderStyle: "solid"
    // backgroundImage: 'url("/assets/images/send.svg"',
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "95% 50%"
  };

  sendStyle = {
    backgroundImage: 'url("/assets/images/send.svg"',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% 50%"
  };

  onValueChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  render() {
    const { triggerNextStep, dispatchMessage } = this.props;

    return this.state.visible ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          height: 40
        }}
      >
        <input
          name="first-name"
          style={{ ...this.inputStyle, paddingLeft: 10 }}
          value={this.state.firstName}
          onChange={e => this.setState({ firstName: e.target.value })}
          onKeyUpCapture={event => {
            if (event.key === "Enter") {
              const lastNameField = document.querySelector(
                `input[name=last-name]`
              );
              if (lastNameField !== null) {
                lastNameField.focus();
              }
            }
          }}
          placeholder="First Name"
        />
        <input
          name="last-name"
          style={{
            ...this.inputStyle,
            paddingLeft: 10,
            marginRight: 5,
            ...this.sendStyle
          }}
          value={this.state.lastName}
          onChange={e => this.setState({ lastName: e.target.value })}
          placeholder="Last Name"
          onBlur={() => {
            if (!this.state.firstName || !this.state.lastName) {
              return;
            }
            dispatchMessage({ ...this.state });
            triggerNextStep({ ...this.state });
            this.setState({ visible: false });
          }}
          onKeyUpCapture={event => {
            if (event.key === "Enter") {
              if (!this.state.firstName || !this.state.lastName) return;
              dispatchMessage({ ...this.state });
              triggerNextStep({ ...this.state });
              this.setState({ visible: false });
            }
          }}
        />

        {/* <Button
          onClick={e => {
            if (!this.state.firstName || !this.state.lastName) return;
            dispatchMessage({ ...this.state });
            triggerNextStep({ ...this.state });
            this.setState({ visible: false });
          }}
          variant="text"
          style={{
            color: "#fff",
            textTransform: "none",
            backgroundColor: "#1565C0",
            borderRadius: 4,
            padding: " 8px 22px",

            boxShadow:
              "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
          }}
        >
          Send
        </Button> */}
      </div>
    ) : (
      <div
        className="sc-htoDjs ilpDAI rsc-ts rsc-ts-user"
        style={{ width: "100%" }}
      >
        <div className="sc-gzVnrw jKQObM rsc-ts-image-container"></div>
        <div
          className="sc-EHOje hDYKxR rsc-ts-bubble"
          style={{
            display: "flex",
            padding: "16px",
            alignSelf: "stretch",
            borderRadius: "16px",
            backgroundColor: "#1565C0",
            color: "white",
            width: "80%",
            maxWidth: "80%",
            fontSize: "16px",
            lineHeight: "1.2",
            fontFamily: "Inter"
          }}
        >
          {this.state.firstName} {this.state.lastName}
        </div>
      </div>
    );
  }
}
