import { invalidInput } from "../common/animations";
import { css } from "styled-components";
import React, { Component } from "react";
import { Mobile, Desktop, Tablet } from "../utils/media-queries";
import { Button } from "@material-ui/core";

export class InlineUserInput extends Component {
  inputStyle = {
    animation: this.props.invalid
      ? css`
          ${invalidInput} .2s ease
        `
      : "",
    // maxWidth: 400,
    marginLeft: 10,
    // minWidth:400,
    width: "100%",
    borderRadius: 4,
    boxShadow: "inset 0 0 2px #E53935",
    boxSizing: "border-box",
    color: "#0E283FD9",
    fontSize: 16,
    opacity: this.props.disabled && !this.props.invalid ? ".5" : "1",
    outline: "none",
    padding: this.props.hasButton ? "16px 52px 16px 10px" : "16px 10px",
    "WebkitAppearance": "none",
    borderWidth: 2,
    borderStyle: "solid",
    backgroundImage: 'url("/assets/images/send.svg"',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%"
  };

  validator = value => {
    if (this.customValidator) {
      const val = this.customValidator(value);
      if (val !== true) {
        this.setState({ errorMessage: val });
        return false;
      }
    }
    return true;
  };

  customValidator = null;

  constructor(props) {
    super(props);
    const { validator, maxLength, dispatchMessage } = props;
    if (validator) {
      this.customValidator = validator;
    }
    if (dispatchMessage) {
      this.dispatchMessage = dispatchMessage.bind(this);
    }
    this.onBlurSubmit = this.onBlurSubmit.bind(this);
    this.state = {
      inputValue: "",
      maxLength: maxLength
    };
  }
  render() {
    const {
      value,
      placeholder,
      type,
      name,
      maxLength,
      customButton,
      defaultInputMessage
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95vw",
          backgroundColor: "#f5f5f5"
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: 5,
            justifyContent: "space-between",
            columnGap: 8
          }}
        >
          <input
            name={name || ""}
            type={type || "textarea"}
            maxLength={maxLength}
            value={value}
            placeholder={placeholder}
            style={this.inputStyle}
            onKeyPress={this.handleKeyPress}
            onChange={this.onValueChange}
            onBlur={this.onBlurSubmit}
          />

          {false && defaultInputMessage && (
            <Button
              onClick={e => {
                this.setState({ inputValue: defaultInputMessage }, () => {
                  this.onBlurSubmit(e);
                });
              }}
              variant="text"
              style={{
                color: "#fff",
                textTransform: "none",
                backgroundColor: "#1565C0",
                borderRadius: 4,
                padding: " 8px 22px",

                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
              }}
            >
              {defaultInputMessage}
            </Button>
          )}
        </div>
        <em style={{ color: "red", textDecoration: "italic" }}>
          {this.state.errorMessage}
        </em>
        {/* <em style={{ marginTop: 3 }}>*click enter to continue</em> */}

        <Desktop>
          <div
            style={{
              width: 47,
              height: 38,
              backgroundColor: "transparent",
              position: "relative",
              float: "right",
              marginLeft: "75%",
              zIndex: 10000000,
              top: -63
            }}
            onClick={this.onBlurSubmit}
          ></div>
        </Desktop>
        <Mobile>
          <div
            style={{
              width: 47,
              height: 38,
              backgroundColor: "transparent",
              position: "relative",
              float: "right",
              marginLeft: "88%",
              zIndex: 10000000,
              top: -63
            }}
            onClick={this.onBlurSubmit}
          ></div>
        </Mobile>

        <Tablet>
          <div
            style={{
              width: 47,
              height: 38,
              backgroundColor: "transparent",
              position: "relative",
              float: "right",
              marginLeft: "70%",
              zIndex: 10000000,
              top: -63
            }}
            onClick={this.onBlurSubmit}
          ></div>
        </Tablet>
      </div>
    );
  }

  handleKeyPress = async event => {
    if (event.key === "Enter") {
      const { inputValue } = this.state;
      const validated = this.validator(inputValue);

      if (!inputValue.length || !validated) return false;
      const { triggerNextStep } = this.props;
      if (this.props.dispatchMessage) {
        await this.props.dispatchMessage(inputValue);
        triggerNextStep({ value: inputValue, sendUserMessage: true });
      } else {
        triggerNextStep({ value: inputValue, sendUserMessage: true });
      }
    }
  };

  onValueChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  onBlurSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();
    const { inputValue } = this.state;
    const validated = this.validator(inputValue);

    if (!inputValue.length || !validated) return false;
    const { triggerNextStep } = this.props;
    if (this.props.dispatchMessage) {
      await this.props.dispatchMessage(inputValue);
      triggerNextStep({ value: inputValue, sendUserMessage: true });
    } else {
      triggerNextStep({ value: inputValue, sendUserMessage: true });
    }

    return false;
  };
}
