import * as fetch from "fetch";

export const saveOrder = async (servmanUrl, companyId, botId, clientInfo, question, answers) => {
  const { client_id, contact_id } = clientInfo;

  const keys = Object.keys(answers);
  const notes = keys
    .filter(x => x !== "Greeting.Selection" && x !== ";")
    .map(key => {
      return `${key}: ${answers[key]}`;
    })
    .join("\r\n");

  const requestPayload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    payload: JSON.stringify({
      companyId: companyId,
      botId: botId,
      client_id,
      contact_id,
      question_id: question.id,
      notes: [notes],
    })
  };

  fetch.fetchUrl(
    servmanUrl + `/v1/servman/order`,
    requestPayload,
    (error, meta, body) => {
      // we really need to figure out better error handling. but here it is.
      const statusCode = meta.status;
      if (statusCode != 200) {
        const str_body = body.toString();
        const jsonBody = JSON.parse(str_body);
        console.log(jsonBody);
      }
      if (error) {
        console.log(error);
      }
    }
  );
};
