import * as moment from "moment";
import React from "react";
import { HtmlMessageComponent } from "../components/html-message.component";
import RatingWidget from "../components/widgets/rating.widget.component";
import { InlineUserInput } from "../components/inline-user-input.component";
import { priceCalculations } from "@workwave_it/lawnbot-pricing-calculations";
import { PrettyButtonGroup } from "../components/pretty-button-group.component";
export const displayTotalAmount = (selectedItems, term, company, currency) => {
  const summary = priceCalculations.getSummaryPricing(selectedItems, term, {
    autoPayPriceMethod: company.autoPayPriceMethod,
    autoPayMonthlyPricePeriod: company.autoPayMonthlyPricePeriod || 12,
    serviceTax: company.serviceTax,
    lookupZipCodeTax: company.lookupZipCodeTax,
    taxOverride: company.taxOverride,
    currency: company.currency
  });
  let t = summary.tax;
  if (isNaN(t)) {
    return (
      <div style={{ width: "100%", display: "inline" }}>
        <span style={{ width: "100%" }}>
          {currency}
          {summary.price.toFixed(2)}
        </span>
        <span style={{ color: "red", fontSize: 14, fontWeight: "bold" }}>
          {company.taxOverride ? ` ${t}` : ""}
        </span>
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%", display: "inline" }}>
        {currency}
        {(summary.price + t).toFixed(2)}
      </div>
    );
  }
};

export const displayMonthPaymentAmount = (
  selectedItems,
  term,
  company,
  currency
) => {
  const monthlyItems = selectedItems.filter(i => {
    return i.services.application[0].usesUpfrontPricing;
  });

  let monthlyPrice = 0;
  monthlyItems.forEach(item => {
    monthlyPrice += item.services.application[0].recurringPrice;
  });

  return (
    <div style={{ width: "100%", display: "inline" }}>
      <span style={{ width: "100%" }}>
        {currency}
        {monthlyPrice.toFixed(2)}
      </span>
    </div>
  );
};

export function howManyApplications(endOn, frequency = "monthly") {
  let mod = frequency;
  switch (frequency) {
    case "daily":
      mod = 1;
      break;
    case "bi-weekly":
      mod = 3.5;
      break;
    case "weekly":
      mod = 7;
      break;
    case "bi-monthly":
      mod = 14;
      break;
    case "monthly":
      mod = 30;
      break;
    default:
  }
  const today = moment();
  const endProgram = moment(endOn);
  const daysLeft = endProgram.diff(today, "days");

  if (daysLeft < 0) return 0;
  return Math.floor(daysLeft / mod) + 1;
}

export function populateQuestion(questions) {
  let qobject = {};
  if (!questions.length) {
    qobject = {
      current_question: null,
      current_type: null,
      current_options: null,
      current_messages: null,
      next: false
    };
  } else {
    const q = questions.shift();
    qobject = {
      current_question: q.question,
      current_type: q.type,
      current_options: q.options,
      current_messages: q.followup
    };
    if (!questions.length) {
      qobject.next = false;
    } else {
      qobject.next = true;
    }
  }
  return qobject;
}

export function clearModalPriceAlerts() {
  //$("#price-breakdown").html('');
}

export function createModalProgramPricingAlert(programInfo) {
  const html = `<div class="alert alert-secondary" role="alert">
    <div class="action-buttons"><button class="circle plus" style="display:none;"></button>
                <button class="circle minus" ></button>
            </div>
        <h4 class="program-name">Program Name</h4>
        <div class="program-description">Description of program
                <span class="program-cost">$500</span>
        </div>
</div>`;
  return html;
  // $("#price-breakdown").append(html);
}

export function generateStepTrigger(program, self) {
  const questions = program.probing_questions || [];
  const steps = [];
  if (!questions) return [];
  questions.forEach(q => {
    const conditionals = q.conditionals || [];

    if (q.component) {
      switch (q.component.type) {
        case "html": {
          const html = q.component.html;
          let newc = <HtmlMessageComponent />;
          q.component = newc;
          q.inputAttributes = { html: html };
          break;
        }
        case "rating": {
          const placeholder = q.component.placeholder || 3;
          let newcc = <RatingWidget placeholder={placeholder} />;
          q.component = newcc;
          q.trigger = payload => {
            const val = payload.value;

            if (val !== "5") {
              return "9orless-lawn";
            } else {
              return "10-lawn";
            }
          };
          break;
        }
        case "userinput": {
          let ph = q.component.placeholder || "Enter Input";
          let newinput = <InlineUserInput placeholder={ph} />;
          q.component = newinput;

          break;
        }
        case "zoneinput": {
          let ph = q.component.placeholder || "Enter Input";
          let newinput = (
            <InlineUserInput
              placeholder={ph}
              customButton={true}
              defaultInputMessage={
                q.metadata ? q.metadata.defaultInputMessage : ""
              }
            />
          );
          q.component = newinput;
          break;
        }
        case "pretty-button-group": {
          let newc = (
            <PrettyButtonGroup
              buttons={q.component.buttons}
              self={self}
              step={q}
            />
          );
          q.component = newc;
        }
        default:
      }
    }

    let trigger = !conditionals.length ? q.trigger : null;
    if (!trigger) {
      trigger = recordLawnbotV1Answer(self, q, null, conditionals);
    }

    if (q.options) {
      q.options = q.options.map(p => {
        if (typeof p === "object") {
          return {
            ...p,
            trigger: recordLawnbotV1Answer(self, q, trigger)
          };
        }
        return {
          value: q,
          label: q,
          trigger: recordLawnbotV1Answer(self, q, trigger)
        };
      });
    } else if (!q.trigger) {
      q.trigger = recordLawnbotV1Answer(self, q, trigger);
    } else if (typeof q.trigger === "string" && q.setVariable) {
      q.trigger = recordLawnbotV1Answer(self, q, q.trigger);
    }

    steps.push(q);
  });
  return steps;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
const recordLawnbotV1Answer = (self, step, trigger, conditionals) => {
  function setVariableCheck(value) {
    if (typeof step.setVariable !== "undefined") {
      const recordValue =
        step.metadata &&
        value === step.metadata.defaultInputMessage &&
        step.metadata.defaultValue
          ? step.metadata.defaultValue
          : value;
      self.answers[step.setVariable] = recordValue;

      const payload = {};
      payload[step.setVariable] = recordValue;
      self.recordChatRecord({ answers: payload });
    }
  }

  return ({ value }) => {
    const next = conditionals
      ? conditionals.filter(
          i =>
            i.matches === value || (i.regex && value.match(new RegExp(i.regex)))
        )
      : [{ trigger }];
    if (next.length) {
      setVariableCheck(value);
      return next[0].trigger ? next[0].trigger : "handle-error";
    } else {
      return "handle-error";
    }
  };
};
